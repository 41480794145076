@charset "UTF-8";

html {
    font-size: 16px;
    scroll-behavior: smooth;
    /* 1rem */
}

/* Указываем box sizing */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-family: "JetBrains Mono", monospace;
    line-height: 1.5;
    background: #1C1C1C;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]),
a[class] {
    text-decoration-skip-ink: auto;
    text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
        animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
        -webkit-transition-duration: 0.01ms !important;
        -o-transition-duration: 0.01ms !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}


.main {
    padding-top: 36px;
    margin-left: 3vw;
    margin-right: 3vw;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;

}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
}

.menu {
    display: flex;
    gap: 6px;
}

.soc {
    display: flex;
    align-items: center;
    gap: 6px;
}

.soc li {
    padding: 11.5px;
    gap: 8px;
    background: #373737;
}

.menu ul li {
    list-style: none;
}

.connect {
    display: flex;
    padding: 16px 20px;
    background: #373737;
    gap: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.04em;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.main-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 74px;
}

.main-logo__text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 62px;
}

.main-logo__text a {
    color: #929292;
    font-size: 16px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: -0.04em;
    text-align: left;

}

.main-logo__text a:hover {
    color: #C3C3C3;
}

.connect:hover,
.main-logo:hover {
    cursor: pointer;
}

.erc404 {
    margin-top: 103px;
    display: flex;
    gap: 62px;
    justify-content: space-between;
}

.erc404-info {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.erc404-info__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.erc404-info__text p {
    font-size: 54px;
    font-weight: 100;
    line-height: 54px;
    letter-spacing: -0.08em;
    text-align: left;
    color: #fff;

}

.erc404-info__about p {
    font-size: 16px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #929292;
    max-width: 471px;
}

.erc404-info__btn {
    display: flex;
    gap: 12px;
}

.erc404-info__btn .mint-btn {
    padding: 16px 20px;
    background: #C6FF25;
    cursor: pointer;
    width: unset;
}

.erc404-info__btn .mint-btn a {
    color: #000;
}

.erc404-info__btn .btn-erc {
    padding: 16px 20px;
    background: #373737;
    color: #FFFFFF;
    cursor: pointer;
}

.btn-erc a {
    color: #FFFFFF;
}

.mint-box {
    margin-top: 30px;
    border-top: solid 1px #373737;
    border-bottom: solid 1px #373737;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../public/img/bg.svg");
}

.topline,
.btline {
    width: 100%;
    height: 24px;
    background: #1C1C1C;
}

.mint {
    margin-top: 54px;
    margin-bottom: 54px;
    padding: 24px;
    background: #1C1C1C;
    display: flex;
    flex-direction: column;
    gap: 26px;
    justify-content: center;
    align-items: center;
}


.mint-links {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.mint-links__img {
    display: flex;
    align-items: center;

}

.mint-links__btns {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.mint-links__btns span {
    color: #C6FF25;
}

.mint-links__btns .uniswap,
.mint-links__btns .opensea {
    padding: 16px 20px;
    background: #373737;
    color: #fff;
    cursor: pointer;
}

.opensea a,
.uniswap a {
    color: #fff;
    ;
}

.counter input {
    width: 350px;
    background: #4A4A4A;
    color: #fff;
    border: unset;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.04em;
    text-align: left;

}

.counter input::placeholder {
    color: #6C6C6C;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.04em;
    text-align: left;

}

.mint-btn {
    background: #C6FF25;
    padding: 16px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
}

.btn-hover:hover {
    background: #595959;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

.mint-btn:hover {
    background: #B2E12E;
    transition: all 0.3s ease 0s;
}

.about,
.rarity {
    position: relative;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.cat2 {
    position: absolute;
    right: -3vw;
    top: 25%;
    z-index: -1;
}

.about-title {
    font-size: 54px;
    font-weight: 100;
    line-height: 59px;
    letter-spacing: -0.08em;
    text-align: left;
    color: #fff;
}

.section {
    font-size: 22px;
    font-weight: 100;
    line-height: 24px;
    letter-spacing: -0.08em;
    text-align: left;
    color: #fff;
}

.about-text {
    font-size: 16px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #929292;
    text-transform: uppercase;
    max-width: 975px;
}

.about-text span {
    color: #C6FF25;
}

.about-text p {
    display: inline;
    font-weight: 700;
}

.rarity-items {
    display: flex;
    justify-content: space-between;
}

.rarity-item {
    display: flex;
    flex-direction: column;
    gap: 31px;
}

.rarity-item__title {
    color: #929292;
    text-transform: uppercase;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 170px;
    margin-bottom: 24px;
    color: #929292;
}

.footer p {
    font-size: 16px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: -0.04em;
    text-align: left;
}

/* POPUP */

.popup-bg {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
}

.popup-bg.active {
    pointer-events: all;
    opacity: 1;
    z-index: 105;
}

.popup {
    position: fixed;
    top: 0px;
    right: 3vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
    transition: all ease 0.3s;
}

.popup.active {
    top: 105px;
    pointer-events: all;
    transition: all ease 0.3s;
}

.popup-exit {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 4px;
    margin-right: 10px;
}

.popup-exit:hover {
    cursor: pointer;
}

.popup-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.popup-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: left;

    color: #fff;
    text-transform: uppercase;
}

.popup-btn-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

.popup-btn-box a p {
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #000;
}


.popup-btn {
    display: flex;
    padding: 12px 20px;
    align-items: center;
}

.popup-btn p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    /* 16.2px */
    letter-spacing: -0.36px;
    color: #000;
}

.popup-content {
    padding: 16px;
    gap: 16px;
    position: relative;
    width: 344px;
    display: flex;
    align-items: flex-start;
    line-height: 150%;
    letter-spacing: -0.64px;
    background: #595959;
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.45);
}


.unselectable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  not supported by any browser */
}


/* BURGER MENU */

.header__burger,
.mob-menu {
    display: none;
}

@media (max-width: 344px) {
    .popup-content {
        width: 288px;
    }

    .popup-btn-box {
        flex-direction: column;
    }
}


@media (max-width: 375px) {
    .counter input {
        width: unset;
    }
}

@media (max-width: 1040px) {
    .naka-lines {
        display: none;
    }
}

@media (max-width: 768px) {
    .menu {
        display: none;
    }

    .rarity-items {
        flex-direction: column;
        align-items: center;
    }

    .rarity-item {
        align-items: center;
    }

    .cat2 {
        display: none;
    }

    .mint-info {
        align-items: center;
    }

    .header-soc {
        display: flex;
        gap: 15px;
    }

    .wallet {
        font-size: 38px;
        text-transform: uppercase;
        color: #FFF;
    }

    .header-menu__item a {
        font-size: 38px;
        text-transform: uppercase;
        color: #FFF;
    }

    .erc404-info__text p {
        font-size: 40px;
    }

    .erc404 {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: unset;
    }

    .main-logo__text {
        display: none;
    }

    .mob-menu__items {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    .mob-menu-link a {
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 110%;
        color: #fff;
    }

    .mob-menu {
        position: fixed;
        /* background-color: rgba(255, 80, 0, 0); */
        /*clip-path: circle(0% at 100% 0); */
        transition: all ease-in-out 200ms;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 8;
        pointer-events: none;
    }

    .mob-menu.active {
        /*clip-path: circle(100% at 100% 0); */
        background-color: rgba(28, 28, 28, 0.9);
        pointer-events: all;
        display: block;
    }

    .header__burger {
        /* margin-top: 30px;
        margin-right: 24px; */
        display: block !important;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 105;
    }

    .header__burger span {
        background-color: #C6FF25;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        top: 9px;
        transition: all 0.3s ease 0s;
    }

    .header__burger:before,
    .header__burger:after {
        content: '';
        background-color: #C6FF25;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transition: all 0.3s ease 0s;
    }

    .header__burger:before {
        top: 0;
    }

    .header__burger:after {
        bottom: 0;
    }

    .header__burger.active:before {
        transform: rotate(45deg);
        top: 9px;
    }

    .header__burger.active:after {
        transform: rotate(-45deg);
        bottom: 9px;
    }

    .header__burger.active span {
        transform: scale(0);
    }
}